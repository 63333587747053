<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Top Brokers</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <get-funded />

    <div
      class="
        uk-section uk-section-primary uk-preserve-color
        in-equity-6
        uk-background-contain uk-background-center
      "
      data-src="/new/in-equity-decor-2.svg"
      data-uk-img=""
      style="background-image: url('/new/in-equity-decor-2.svg')"
    >
      <div class="uk-container uk-margin-small-bottom">
        <div class="uk-grid uk-flex uk-flex-center">
          <div class="uk-width-3-4@m uk-margin-top">
            <div
              class="
                uk-grid uk-child-width-1-3@s uk-child-width-1-3@m uk-text-center
                in-register
              "
              data-uk-grid=""
            >
              <div class="uk-width-1-1 in-h-line uk-first-column">
                <h2>Getting started is easy</h2>
              </div>
              <div class="uk-grid-margin uk-first-column">
                <span class="in-icon-wrap circle">1</span>
                <p>Create an account</p>
              </div>
              <div class="uk-grid-margin">
                <span class="in-icon-wrap circle">2</span>
                <p>
                  Take the first course in the academy to learn the
                  fundamentals.
                </p>
              </div>
              <div class="uk-grid-margin">
                <span class="in-icon-wrap circle">3</span>
                <p>Join the community</p>
              </div>
              <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                <router-link
                  :to="{ name: 'register' }"
                  class="uk-button uk-button-text"
                  >Open account<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetFunded from "../shared/GetFunded.vue";

export default {
  components: {
    GetFunded
  },
};
</script>
